<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-2">
        <div class="">
          <b-form-group
              class="mb-0">
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-2">

        <download-excel
            :data="items"
            class="btn btn-primary"
            :fields="json_fields"
            worksheet="Agent wise Prescription list"
            name="Agent wise Prescription list">
          <i class="fas fa-file-excel"></i> Export Excel
        </download-excel>


      </div>
      <div class="col-md-8">
        <b-form-group
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"/>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
            </b-input-group-append>

          </b-input-group>

        </b-form-group>
      </div>
    </div>

    <div class="row mt-5 align-items-center">
      <div class="col-md-4">
        <label for="">From</label>
        <input type="date" v-model="startDate" class="form-control">
      </div>
      <div class="col-md-4">
        <label for="">To</label>
        <input type="date" v-model="endDate" class="form-control">
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table
            striped hover
            show-empty
            :items="filtered"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            class="mt-10"
        >

          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
            </td>
          </template>

          <template #cell(connections)="row">
            <div v-if="row.item">
              <b-badge variant="success" v-if="row.item.connections ? row.item.connections.is_accepted == '1': '0'"
                       class="font-size-10">Connected
              </b-badge>
              <b-badge variant="danger" v-else class="font-size-10"> Not connected</b-badge>
            </div>
          </template>

          <template #cell(prescription_identifier)="row">
            <div class="">
              <a class="text-info font-weight-bold" :href="prescription_url(row.item.url)" target="_black">
                {{ row.item.prescription_identifier }} </a>
            </div>
          </template>

          <template #cell(patients)="row">
            <div v-if="row.item.patients">
              <div v-if="row.item.patients">
                <p>
                  {{ row.item.patients.fullname }}
                </p>
              </div>
              <div v-else>
                <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
              </div>
            </div>
          </template>

          <template #cell(doctors)="row">
            <div v-if="row.item.doctors">
              <div v-if="row.item.doctors">
                <p>
                  {{ row.item.doctors.fullname }}
                </p>
              </div>
              <div v-else>
                <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
              </div>
            </div>
          </template>

          <template #cell(phone_number)="row">
            <!--            <div v-if="row.item.phone_number.">-->
            <div v-if="row.item.doctors">
              <p>
                {{ row.item.doctors.phone_number }}
              </p>
            </div>
            <!--              <div v-else>-->
            <!--                <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>-->
            <!--              </div>-->
            <!--            </div>-->
          </template>

          <template #cell(phone_number)="row">
            <div v-if="row.item.doctors">
              <div v-if="row.item.doctors">
                <p>
                  {{ row.item.doctors.phone_number }}
                </p>
              </div>
              <div v-else>
                <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
              </div>
            </div>
          </template>

          <template #cell(created_date)="row">

              <div v-if="row.item.created_date">
                <p>
                  {{ row.item.created_date }} {{ row.item.created_at }}
                </p>
              </div>
              <div v-else>
                <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
              </div>

          </template>

        </b-table>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            size="lg"
            class="my-0"
        ></b-pagination>
      </div>
    </div>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {underScoreToSpace} from "../../../healper/nameFormating";

export default {
  name: "PrescriptionList",
  data() {
    return {
      // user: '',
      // Download options
      downloadLoading: true,
      filename: '',
      autoWidth: true,
      bookType: 'xlsx',
      json_fields: {
        "Fullname": {
          field: "fullname"
        },
        "Slug": {
          field: "slug"
        },
        "Phone number": {
          field: "phone_number"
        },
        "Age": {
          field: "age"
        },
        "Wallet": {
          field: "wallet"
        },
      },

      loading: true,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 20, 40, 60, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',

      localActive: false,
      items: [],
      fields: [

        {
          key: 'patients',
          label: 'Patient name',
          sortable: true
        },
        {
          key: 'doctors',
          label: 'Doctor name',
          sortable: true
        },
        {
          key: 'phone_number',
          label: 'Doctor phone number',
          sortable: true
        },
        {
          key: 'is_paid',
          label: 'payment status',
          sortable: true
        },
        {
          key: 'prescription_identifier',
          label: 'Prescription identifier',
          sortable: true
        },
        {
          key: 'created_date',
          label: 'Created',
          sortable: true
        }
      ],
      filter: null,
      filterOn: [],
      filters: {
        patients: '',
        doctors: '',
        phone_number: '',
        is_paid: '',
        prescription_identifier: '',
        created_date: '',
      },
      startDate: null,
      endDate: null,
      show: true,
      base_url: process.env.VUE_APP_API_URL,
      users: [],

    }
  },
  metaInfo: {
    title: 'Orko Health Ltd | Agent wise doctor list',
    htmlAttrs: {
      lang: 'en-US'
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Agent wise prescription list", route: "/agent-wise-doctor-list"},
    ]);
  },
  created() {
    this.getPatientLists();
  },
  computed: {
    rows() {
      return this.users.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {

      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const filtered = this.items.filter(item => {
        const itemDate = new Date(item.created_date)
        if (startDate && endDate) {
          return startDate <= itemDate && itemDate <= endDate;
        }
        if (startDate && !endDate) {
          return startDate <= itemDate;
        }
        if (!startDate && endDate) {
          return itemDate <= endDate;
        }
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })


      return filtered.length > 0 ? filtered : [{
        patients: '',
        doctors: '',
        phone_number: '',
        is_paid: '',
        prescription_identifier: '',
        created_date: '',
      }]
    },
    filteredData() {

      let filterType = this.selectedType;
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
      return itemsByType
          .filter(item => {
            const itemDate = new Date(item.registration_date)
            if (startDate && endDate) {
              return startDate <= itemDate && itemDate <= endDate;
            }
            if (startDate && !endDate) {
              return startDate <= itemDate;
            }
            if (!startDate && endDate) {
              return itemDate <= endDate;
            }
            return true;
          })
    }
  },
  methods: {

    getPatientLists() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/partner/agent/prescription/list/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.items = response.data.data
            this.users = response.data.data
            this.loading = false;
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
    },
    underScoreToSpace,
    prescription_url(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/${url}`;
      } else {
        return ''
      }
    },
  }
}
</script>
<style>
.hide {
  display: none;
}

.text-green {
  color: green;
}

.is_online {
  position: relative;
}

.is_online_icon {
  position: absolute;
  left: 0;
  bottom: 4px;
  margin-left: 14px;
  font-size: 12px;
}

.user_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  text-align: center;
}

.font-size-10 {
  font-size: 10px;
}
</style>